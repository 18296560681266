<template>
  <v-container fluid>
    <v-slide-x-transition mode="out-in">
      <div
        v-if="selectedView === '1'"
        key="1"
        class="pa-5"
      >
        <v-flex class="mt-5">
          <h1 class="mb-6">
            Eshop Cross Sale
          </h1>
          <v-spacer />
        </v-flex>
        <v-card elevation="0" rounded="xl">
          <v-card-title>
            <template slot="progress">
              <v-progress-linear
                color="deep-purple"
                height="40"
              />
            </template>
            <span>DO NOT FORGET TO SAVE >>>>>>>>>>>>>>></span>
            <v-spacer />
            !! <v-btn
              color="green"
              dark
              large
              @click="saveCrossSaleAndUpSaleData"
            >
              Save CrossSale items
            </v-btn> !!
          </v-card-title>

          <v-row
            v-for="item in items"
            :key="item.id"
            class="pl-4 pr-4"
          >
            <v-col cols="4">
              <div style="border: 1px solid lightgray; padding: 10px; height: 250px; border-radius: 5px;">
                <div>
                  <div style="display: inline-block;">
                    <input
                      type="checkbox"
                      color="blue"
                      placeholder="enabled?"
                      :checked="isEnabled(item) ? true : false"
                      @change="enableCrossSaleForItem(item)"
                    >
                    <span style="margin-left: 10px;">enabled in cross?</span>
                  </div>
                  <div style="display: inline-block; margin-left: 20px;">
                    <div class="font-weight-bold">
                      Item Name : <span class="blue--text font-weight-regular">{{ item.itemName }}</span>, <span class="font-weight-bold">Item enabled in eshop? : <span class="font-weight-regular" :class="item.itemEnabled ? 'green--text' : 'red--text'">{{ item.itemEnabled }}</span></span>
                    </div>
                  </div>
                </div>
                <div>
                  <v-img style="max-width: 150px; margin-top: 30px; max-height: 150px;" :src="item.itemPicturesAWSLinks[0]" />
                </div>
              </div>
            </v-col>
            <v-col v-if="isEnabled(item)" cols="6">
              <div style="border: 1px solid lightgray; padding-left: 10px; padding-right: 10px; height: 250px; border-radius: 5px;">
                <v-list style="max-height: 250px" class="overflow-y-auto">
                  <div
                    v-for="itemToCrossSell in items"
                    :key="itemToCrossSell.id"
                    style="margin-top: 10px;"
                  >
                    <input
                      :checked="isEnabledInCrossSaleForItem(item, itemToCrossSell) ? true : false"
                      type="checkbox"
                      color="green"
                      @change="enableItemToCrossSaleForItem(item, itemToCrossSell)"
                    >
                    <span style="margin-left: 10px;">{{ itemToCrossSell.itemName }}</span>
                    <img style="max-width: 30px; position: relative; top: 10px; left: 10px;" :src="itemToCrossSell.itemPicturesAWSLinks[0]">
                    <v-divider style="margin-top: 10px;" />
                  </div>
                </v-list>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </v-slide-x-transition>
  </v-container>
</template>

<script>

export default {
  name: 'Users',
  components: {

  },
  data () {
    return {
      selectedView: '1',
      items: [],
      isMounted: false,
      crossSaleItems: [
      ]
    }
  },
  computed: {
  },
  watch: {
  },
  mounted () {
    this.pullEshopData()
    this.pullCrossSaleData()
    this.isMounted = true
  },
  methods: {
    pullEshopData () {
      this.showProgress = true
      this.$store.dispatch('pullEshopData').then(resp => {
        if (resp) {
          this.items = resp.data
          this.showProgress = false
        }
        this.showProgress = false
      })
    },
    pullCrossSaleData () {
      this.$store.dispatch('pullCrossSaleAndUpSaleData', { type: 'cross-sale' }).then((resp) => {
        this.crossSaleItems = resp.data[0].items
      })
    },
    isEnabled (item) {
      let result = false
      const index = this.crossSaleItems.findIndex(i => i.item === item.id)
      if (index !== -1) result = true
      return result
    },
    isEnabledInCrossSaleForItem (parentItem, crossItem) {
      let result = false
      const index = this.crossSaleItems.findIndex(i => i.item === parentItem.id)
      if (index !== -1) {
        const whereIs = this.crossSaleItems[index].products.length ? this.crossSaleItems[index].products : []
        const crossItemIndex = whereIs.findIndex(item => item.id === crossItem.id)

        if (crossItemIndex !== -1) result = true
      }

      return result
    },
    enableCrossSaleForItem (item) {
      const index = this.crossSaleItems.findIndex(i => i.item === item.id)
      if (index === -1) {
        const object = {
          item: item.id,
          products: []
        }
        this.crossSaleItems.push(object)
      } else {
        this.crossSaleItems.splice(index, 1)
      }
    },
    enableItemToCrossSaleForItem (parentItem, crossItem) {
      const index = this.crossSaleItems.findIndex(i => i.item === parentItem.id)
      if (index !== -1) {
        const crossItemIndex = this.crossSaleItems[index].products.findIndex(item => item.id === crossItem.id)
        if (crossItemIndex !== -1) {
          this.crossSaleItems[index].products.splice(crossItemIndex, 1)
        } else {
          this.crossSaleItems[index].products.push(crossItem)
        }
      }
    },
    saveCrossSaleAndUpSaleData () {
      const object = {
        type: 'cross-sale',
        items: this.crossSaleItems
      }
      this.$store.dispatch('saveCrossSaleAndUpSaleData', object).then((resp) => {
        this.pullCrossSaleData()
      })
    }
  }
}
</script>

<style scoped>
</style>
